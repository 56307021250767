export const formatCurrency = (number: number) =>
  new Intl.NumberFormat("id-ID").format(number);

export const formatBankString = (str: string) => {
  try {
    str
      .split("_")
      .map((s) => {
        return s[0].toUpperCase() + s.substring(1).toLowerCase();
      })
      .join(" ");
  } catch {
    return "";
  }
};
