import { MediaPlayerInstance } from "@vidstack/react";
import { MutableRefObject } from "react";
import Player from "video.js/dist/types/player";
import createStore from "zustand";

export type MediaStore = {
  // state
  player: MutableRefObject<MediaPlayerInstance>;
  playerVideoJs: MutableRefObject<Player>;

  // actions
  setPlayer: (player: MutableRefObject<MediaPlayerInstance>) => void;
  setPlayerVideoJs: (player: MutableRefObject<Player>) => void;
};

export const useMediaStore = createStore<MediaStore>((set) => ({
  // state
  player: null,
  playerVideoJs: null,

  // actions
  setPlayer(player) {
    set({ player });
  },
  setPlayerVideoJs(player) {
    set({ playerVideoJs: player });
  },
}));
