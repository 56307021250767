import { CONST } from "lib/const";
import { useAuthStore } from "modules/Auth/authStore";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
// import { VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import "videojs-mux";
import { EndedOverlay } from "./VideoPlayer/EndedOverlay";
import { trackEvent } from "lib/amplitude";
import {
  getLessonIdBySlug,
  getLessonNumberBySlug,
  useCourseStore,
} from "./courseStore";
import { useIsSubdomain, useIsTenant, isMalukuURL, isGateURL } from "lib/host";
import "videojs-youtube";
import Hls from "hls.js";
// import {
//   deleteVideoFromIndexedDB,
//   getVideoFromIndexedDB,
//   saveVideoToIndexedDB,
// } from "lib/video";
import { useToastStore } from "components/Toast/toastStore";
import { useMediaStore } from "./mediaStore";

export const VideoJS = ({ url, isHovering = false }) => {
  const videoRef = useRef(null);
  const playerRef = useRef<Player>(null);
  const isSubdomainTenant = useIsSubdomain();
  const isMoodle = useIsTenant();
  const isMalukuTenant =
    typeof window !== "undefined" && isMalukuURL(window.location.hostname);
  const isGate =
    typeof window !== "undefined" && isGateURL(window.location.hostname);
  // const currentLesson = useCourseStore((state) => state.currentLesson);

  const [courseStatus, courseEnrollmentId, currentLesson] = useCourseStore(
    (state) => [
      state.courseStatus,
      state.courseEnrollmentId,
      state.currentLesson,
    ]
  );
  const showToast = useToastStore((state) => state.showToast);

  const [isEnded, setEnded] = useState(false);

  const router = useRouter();

  const currentUserId = useAuthStore((s) => s?.currentUser?.id);

  const lessonSlug =
    typeof router.query.lesson == "string" ? router.query.lesson : "";
  const courseSlug =
    typeof router.query.courseSlug == "string" ? router.query.courseSlug : "";
  // const { options, onReady } = props;

  const videoTitle = lessonSlug || courseSlug;

  const getVideoTitle = (title: string, name: any) => {
    if (name) return name;

    return (
      title
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") || "untitled"
    );
  };

  let videoCDN = "Cloudfront";
  let videoStreamingType = "VOD";

  if (url?.includes("fast.gokampus.com")) videoCDN = "Fastly";
  else if (url?.includes("gokampus.cachefly.net")) videoCDN = "CacheFly";

  const currentSource = url?.includes("youtube")
    ? { src: url, type: "video/youtube" }
    : url?.includes("blob")
    ? { src: url, type: "video/mp4" }
    : { src: url };

  let playerName = "GoKampus Player";

  if (isGate) {
    playerName = "GoKampus App Player";
  }

  if (isMalukuTenant) {
    playerName = "GoKampus Maluku Player";
  }

  if (isHovering) {
    playerName += " (Hover)";
  }

  const options = {
    autoplay: url !== "",
    controls: !isHovering,
    // Change to false to show playbackRates on mobile
    responsive: false,
    playsinline: true,
    // Docs: https://videojs.com/guides/options/#playbackrates
    playbackRates: [2, 1.5, 1, 0.5],
    controlBar: {
      skipButtons: {
        backward: 10,
        forward: 10,
      },
      pictureInPictureToggle: true,
    },
    userActions: {
      // default hotkeys:
      // - 'f' for toggle fullscreen
      // - 'space' for toggle play/pause
      // - 'm' for toggle mute
      hotkeys: true,
    },
    // fluid: true,
    // https://stackoverflow.com/questions/49930680/how-to-handle-uncaught-in-promise-domexception-play-failed-because-the-use/50742427#50742427
    muted: isHovering,
    preload: true,
    sources: [currentSource],
    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: CONST.MUX_ENV_KEY,
          // Metadata
          player_name: playerName,
          viewer_user_id: currentUserId,
          video_id: lessonSlug,
          video_cdn: videoCDN,
          video_stream_type: videoStreamingType,
          video_title: lessonSlug,
        },
      },
    },
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
  };

  const { setPlayerVideoJs } = useMediaStore();

  useEffect(() => {
    if (playerRef) {
      setPlayerVideoJs(playerRef);
    }
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player: any = (playerRef.current = videojs(
        videoElement,
        options,
        () => {
          // videojs.log("player is ready");
          // onReady && onReady(player);
        }
      ));

      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      // Initialize quality levels
      const qualityLevels = player.qualityLevels();

      // Example: Log available quality levels
      qualityLevels.on("addqualitylevel", (event) => {
        console.log("New quality level added:", event.qualityLevel);
      });

      // Example: Log when quality level changes
      qualityLevels.on("change", () => {
        const selectedQuality = qualityLevels[qualityLevels.selectedIndex];
        console.log("Selected quality level:", selectedQuality);
      });

      // Example: Enable/disable specific quality levels
      for (let i = 0; i < qualityLevels.length; i++) {
        const quality = qualityLevels[i];
        if (quality.height >= 720) {
          quality.enabled = true;
        } else {
          quality.enabled = false;
        }
      }

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoRef, isHovering, url, playerRef]);

  // const displayVideo = async () => {
  //   try {
  //     const videoBlob: any = await getVideoFromIndexedDB(videoTitle);
  //     if (videoBlob) {
  //       const url = URL.createObjectURL(videoBlob.blob);
  //       playerRef.current.src({ type: videoBlob.type, src: url });
  //     }
  //   } catch (error) {
  //     console.error("Failed to load video from IndexedDB", error);
  //   }
  // };

  let hls;
  const videoEl = videoRef.current;

  const fetchVideoFromNetwork = (url) => {
    console.log("🚀 2 url ", url);

    var dataStream = {
      video: [],
      audio: [],
    };
    if (url.includes(".m3u8")) {
      if (Hls.isSupported()) {
        hls = new Hls();

        // @ts-ignore
        window.hls = hls;

        hls.loadSource(url);
        hls.attachMedia(videoEl);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, a) {
          console.log("🚀 3 apending data", a);

          videoEl.play();
          hls.on(Hls.Events.BUFFER_APPENDING, function (event, data) {
            console.log("🚀 3 apending", data);
            dataStream[data.type].push(data.data);
          });
        });
      }
    }

    console.log("🚀 4 dataStream", dataStream);
  };

  const fetchVideo = (url?: string) => {
    fetchVideoFromNetwork(url);
  };

  useEffect(() => {
    if (url) {
      console.log("🚀 1", url);
      fetchVideo(url);
    }
    //  else {
    //   displayVideo();
    // }
  }, [url]);

  // useEffect(() => {
  //   const player = playerRef.current;

  //   if (!isHovering) player.pause()
  // }, [isHovering])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    const onPlay = () => {
      trackEvent({
        event: "lms_video_playpause_a",
        property: {
          course_slug: courseSlug,
          is_play: true,
          lesson_slug: lessonSlug,
          current_video_time: player.currentTime(),
        },
      });
    };
    const onPause = () => {
      trackEvent({
        event: "lms_video_playpause_a",
        property: {
          course_slug: courseSlug,
          is_play: false,
          lesson_slug: lessonSlug,
          current_video_time: player.currentTime(),
        },
      });
    };
    const onEnterPip = () => {
      trackEvent({
        event: "lms_video_pip_a",
        property: {
          course_slug: courseSlug,
          is_pip: true,
          lesson_slug: lessonSlug,
          current_video_time: player.currentTime(),
        },
      });
    };
    const onLeavePip = () => {
      trackEvent({
        event: "lms_video_pip_a",
        property: {
          course_slug: courseSlug,
          is_pip: false,
          lesson_slug: lessonSlug,
          current_video_time: player.currentTime(),
        },
      });
    };
    const onEnded = () => {
      setEnded(true);
      trackEvent({
        event: "lms_lesson_complete_a",
        property: {
          course_slug: courseSlug,
          lesson_slug: lessonSlug,
          course_enrollment_id: courseEnrollmentId,
          type: currentLesson?.type,
          lesson_num: getLessonNumberBySlug(lessonSlug, courseStatus),
          is_marked_as_done: false,
          marked_lesson_slug: lessonSlug,
        },
      });
    };

    const onClickSeekbar = () => {
      trackEvent({
        event: "lms_video_timeline_a",
        property: {
          course_slug: courseSlug,
          lesson_slug: lessonSlug,
          current_video_time: player.currentTime(),
        },
      });
    };

    const onVolumeChange = () => {
      trackEvent({
        event: "lms_video_volume_a",
        property: {
          course_slug: courseSlug,
          lesson_slug: lessonSlug,
          current_video_time: player.volume(),
        },
      });
    };

    const onFullScreenChange = () => {
      trackEvent({
        event: "lms_video_fullscreen_a",
        property: {
          course_slug: courseSlug,
          lesson_slug: lessonSlug,
          is_fullscreen: player.isFullscreen(),
        },
      });
    };
    const onPlaybackChange = () => {
      const eventProperties = {
        course_slug: courseSlug,
        lesson_slug: lessonSlug,
        course_enrollment_id: courseEnrollmentId,
        type: currentLesson?.type,
        course_id: courseStatus.id,
        chapter_num: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.chapterNum,
        lesson_id: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.lessonId,
        lesson_num: getLessonNumberBySlug(currentLesson?.slug, courseStatus),
        speed: player.playbackRate(),
      };
      trackEvent({
        event: "lms_video_setting_speed_selected_a",
        property: eventProperties,
      });
    };

    const onSkipBackward = () => {
      const eventProperties = {
        course_slug: courseSlug,
        lesson_slug: lessonSlug,
        course_enrollment_id: courseEnrollmentId,
        type: currentLesson?.type,
        course_id: courseStatus.id,
        chapter_num: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.chapterNum,
        lesson_id: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.lessonId,
        lesson_num: getLessonNumberBySlug(currentLesson?.slug, courseStatus),
        current_video_time: player.currentTime(),
      };
      trackEvent({
        event: "lms_video_backward_a",
        property: eventProperties,
      });
    };

    const onSkipForward = () => {
      const eventProperties = {
        course_slug: courseSlug,
        lesson_slug: lessonSlug,
        course_enrollment_id: courseEnrollmentId,
        type: currentLesson?.type,
        course_id: courseStatus.id,
        chapter_num: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.chapterNum,
        lesson_id: getLessonIdBySlug(courseStatus.chapters, lessonSlug)
          ?.lessonId,
        lesson_num: getLessonNumberBySlug(currentLesson?.slug, courseStatus),
        current_video_time: player.currentTime(),
      };
      trackEvent({
        event: "lms_video_forward_a",
        property: eventProperties,
      });
    };

    player.on("play", onPlay);
    player.on("pause", onPause);
    player.on("enterpictureinpicture", onEnterPip);
    player.on("leavepictureinpicture", onLeavePip);
    player.on("ended", onEnded);
    player.on("volumechange", onVolumeChange);
    player.on("fullscreenchange", onFullScreenChange);
    player.on("ratechange", onPlaybackChange);

    // @ts-ignore
    player.controlBar?.skipBackward?.on("click", onSkipBackward);

    // @ts-ignore
    player.controlBar?.skipForward?.on("click", onSkipForward);

    // @ts-ignore
    player.controlBar?.progressControl?.seekBar?.on("click", onClickSeekbar);

    return () => {
      if (player && !player.isDisposed()) {
        player.off("play", onPlay);
        player.off("pause", onPause);
        player.off("enterpictureinpicture", onEnterPip);
        player.off("leavepictureinpicture", onLeavePip);
        player.off("ended", onEnded);
        player.off("volumechange", onVolumeChange);
        player.off("fullscreenchange", onFullScreenChange);

        // @ts-ignore
        player.controlBar?.progressControl?.seekBar?.off(
          "click",
          onClickSeekbar
        );

        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return isEnded && !isHovering ? (
    <div className="w-full h-full flex items-center relative bg-black">
      <EndedOverlay
        onClickNext={() => {
          setEnded(false);
        }}
      />
    </div>
  ) : (
    <div data-vjs-player className="w-full h-full">
      <div ref={videoRef} className="w-full h-full" />
    </div>
  );
};
